// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aplicaciones-moviles-js": () => import("./../../../src/pages/aplicaciones-moviles.js" /* webpackChunkName: "component---src-pages-aplicaciones-moviles-js" */),
  "component---src-pages-aplicaciones-web-js": () => import("./../../../src/pages/aplicaciones-web.js" /* webpackChunkName: "component---src-pages-aplicaciones-web-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-consultoria-informatica-js": () => import("./../../../src/pages/consultoria-informatica.js" /* webpackChunkName: "component---src-pages-consultoria-informatica-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paginas-web-js": () => import("./../../../src/pages/paginas-web.js" /* webpackChunkName: "component---src-pages-paginas-web-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

